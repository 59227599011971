.app {
    text-align: center;
    height: 100%;
    min-height: 100vh;
    margin: 0;
}

.image {
    background-image: url("./assets/img/wp_site_background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100%;
}

@media (max-width: 1000px) {
    .app {
        background-position: -30px;
    }
}

@media (max-width: 900px) {
    .app {
        background-position: -100px;
    }
}

@media (max-width: 700px) {
    .app {
        background-position: -200px;
    }
}

